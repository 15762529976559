import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Home from './pages/home/home.tsx';
import About from './pages/about/about.tsx';
import Login from './pages/login/login.tsx';
import Layout from './Layout.tsx';
import BtnWhatsapp from './commons/utils/Whatsapp.tsx';
import MyProjects from './pages/projects/myProjects.tsx';
import Detail from './pages/projects/detail.tsx';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<Layout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/myprojects" element={<MyProjects />} />
          <Route path="/detail" element={<Detail />} />
        </Route>
      </Routes>
      <BtnWhatsapp />
    </ThemeProvider>
  );
}

export default App;
