import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#282828',
        },
        secondary: {
            main: '#9A9A9A',
        },
    },
});

export default theme;
