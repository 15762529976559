import React from "react";
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const BtnWhatsapp = () => {

    const handleRefWhatsapp = () => {
        const href = `https://api.whatsapp.com/send?phone=1157105568`;
        window.open(href, '_blank');
    };
    return (
        <>
            <body style={{
                display:"block",
                width:"60px",
                height:"55px",
                color:"#fff",
                position: "fixed",
                right:"20px",
                bottom:"40px",
                borderRadius:"5%",
                lineHeight:"55px",
                textAlign:"center",
                zIndex:"999",
                background: "transparent"
            }}>
                <IconButton
                    aria-label="toggle visibility"
                    style={{ fontSize: '50px', background:'#ebebeb' }} 
                >
                    <WhatsAppIcon
                        onClick={() => handleRefWhatsapp()}
                        style={{ fontSize: 'inherit', color:'#00A884' }} 
                    />
                </IconButton>
            </body>
        </>
    );  
};

export default BtnWhatsapp;