import * as React from 'react';
import { DefaultizedPieValueType } from '@mui/x-charts/models';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

const data = [
    { label: 'Proyectos en Obra', value: 400, color: '#0088FE' },
    { label: 'Proyectos Finalizados', value: 300, color: '#00C49F' },
    { label: 'Proyectos Cerrados', value: 300, color: '#FFBB28' },
];

const sizing = {
    margin: { right: 5 },
    width: 400,
    height: 400,
    legend: { hidden: true },
};
const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
};

const Pie: React.FC = () => {
    return (
        <PieChart
        series={[
            {
            outerRadius: 140,
            data,
            arcLabel: getArcLabel,
            },
        ]}
        sx={{
            [`& .${pieArcLabelClasses.root}`]: {
            fill: 'white',
            fontSize: 16,
            },
        }}
        {...sizing}
        />
    );
}

export default Pie;
