import React from 'react';
import { Box, Typography, Grid, TextField, Button } from '@mui/material';
import Project from '../../commons/project/card.tsx'

const MyProjects: React.FC = () => {
    return (
        <Box>
            <Box sx={{ mt: 4, textAlign: 'center', marginTop: '5vw', marginBottom: '10vw'}}>
                <Typography variant="h3" component="h2" sx={{ mb: 3 }}>
                    Mis proyectos
                </Typography>

                {/*FILTRO*/}
                <Grid container spacing={3} justifyContent="center" style={{ marginBottom: '2vw' }}>
                    <Grid item>
                        <TextField
                        label="Nombre de proyecto"
                        variant="outlined"
                        size="small"
                        margin="dense"
                        style={{ marginRight: '16px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                        label="Desde"
                        type="date"
                        variant="outlined"
                        size="small"
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        style={{ marginRight: '16px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                        label="Hasta"
                        type="date"
                        variant="outlined"
                        size="small"
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item style={{marginTop: '0.8vh;'}}>
                        <Button variant="contained" color="primary" sx={{ ml: 2, marginTop: '0.8vh;' }}>
                        Buscar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" sx={{ ml: 2, marginTop: '0.8vh;' }}>
                        Filtrar
                        </Button>
                    </Grid>
                    </Grid>

                <Grid container spacing={3} justifyContent="center" style={{ marginTop: '4vw'}}>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/menhir-proyectos-17-1-510x340.png"
                            title="Triumph"
                            subtitle="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour."
                            date="12/12/2024"
                        />
                    </Grid>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/menhir-proyectos-18-1-510x340.png"
                            title="Kawasaki"
                            subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                            date="11/12/2024"
                        />
                    </Grid>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/fotos-website-menhir-ok-11-510x340.jpg"
                            title="Motoplex Devoto"
                            subtitle="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
                            date="11/12/2024"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent="center" style={{ marginTop: '4vw'}}>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/fotos-website-menhir-ok-14-510x340.jpg"
                            title="Jeep Nordelta"
                            subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                            date="23/11/2024"
                        />
                    </Grid>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/fotos-website-menhir-ok-13-510x340.jpg"
                            title="Peugeot Belchamp"
                            subtitle="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour."

                            date="01/10/2024"
                        />
                    </Grid>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/fotos-website-menhir-ok-07-510x340.jpg"
                            title="Chevrolet Thaun"
                            subtitle="Contrary to popular belief, Lorem Ipsum is not simply random text. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour."
                            date="14/09/2024"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default MyProjects;
