import * as React from 'react';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';

interface ProjectProps {
    image: string; 
    title: string; 
    subtitle: string;
    date: string; 
}

const Project: React.FC<ProjectProps> = (props: ProjectProps) => {
    
    const navigate = useNavigate();
    const handleDetail = () => {
        navigate('/detail');
    };

    return (
        <div onClick={handleDetail}>
            <Card sx={{ maxWidth: 345, height: 400 }}>
                <CardHeader
                    title={props.title}
                    subheader={props.date}
                />
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image={props.image}
                        alt="green iguana"
                    />
                    <CardContent>
                        
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {props.subtitle}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
}

export default Project;
