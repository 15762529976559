import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ResponsiveAppBar from './commons/header/ResponsiveAppBar.tsx'; // Ajusta el path
import Footer from './commons/footer/Footer.tsx'; // Ajusta el path
import { Box } from '@mui/material';

const Layout: React.FC = () => {
  const location = useLocation();

  // No mostrar el AppBar ni el Footer en la página de login
  const hideAppBarAndFooter = location.pathname === '/';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh', // Asegura que el contenido ocupe toda la altura de la ventana
      }}
    >
      {!hideAppBarAndFooter && <ResponsiveAppBar />}
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Outlet />
      </Box>
      {!hideAppBarAndFooter && <Footer />}
    </Box>
  );
};

export default Layout;
