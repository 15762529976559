import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#282c34',
        color: '#FFFFFF',
        textAlign: 'center',
        py: 2, // Padding en el eje Y (arriba y abajo)
        mt: 'auto', // Empuja el footer hacia el final de la página
      }}
    >
      <Typography variant="body2">
        © {new Date().getFullYear()} MENHIR  
      </Typography>
      <Typography variant="body2">
        Contacto Tel: 54 11 4545 5959 Mail: <p><a href="menhir@menhir1.com">menhir@menhir1.com</a></p>
      </Typography>
    </Box>
  );
};

export default Footer;
