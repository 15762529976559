import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Typography, Button, Paper } from '@mui/material';
import './login.css';

const Login: React.FC = () => {
  const navigate = useNavigate();

  const handleLogin = (): void => {
    navigate('/home');
  };

    return (
    <div className="login-container">
        {/* Sección izquierda */}
        <div className="login-left">
            <img src="https://menhir1.com/wp-content/uploads/2024/04/logo-menhir-2.png" alt="Logo" className="login-logo" />
            <Paper elevation={6} className="login-form">
                <Typography variant="h5" gutterBottom>
                    Iniciar sesión
                </Typography>
                <TextField
                    label="Usuario"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: '#9A9A9A' } }}
                    InputProps={{
                    style: { color: '#000' },
                    }}
                />
                <TextField
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { color: '#9A9A9A' } }}
                    InputProps={{
                    style: { color: '#000' },
                    }}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className="login-button"
                    style={{ color: "white"}}
                    onClick={handleLogin}
                >
                    Iniciar sesión
                </Button>
            </Paper>
        </div>

        {/* Sección derecha */}
        <div className="login-right"></div>
        </div>
    );
};

export default Login;
