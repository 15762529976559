import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './home.css'; 
import Project from '../../commons/project/card.tsx'
import Pie from '../charts/pie.tsx';

const Home: React.FC = () => {
    return (
        <Box>
             {/* Carrusel */}
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                {/* Contenedor del carrusel con el título superpuesto */}
                <div className="carousel-container">
                    {/* Título superpuesto */}
                    <Typography variant="h3" className="carousel-overlay">
                        Diseñando el futuro
                    </Typography>

                    {/* Carrusel */}
                    <Carousel
                        showThumbs={false}
                        autoPlay
                        infiniteLoop
                        interval={5000}
                        showStatus={false}
                        emulateTouch
                        dynamicHeight={false}
                    >
                        <div>
                            <img
                                src="https://menhir1.com/wp-content/uploads/2024/03/lanyon-quoit-a-neolithic-dolmen-2023-11-27-05-08-43-utc.jpg"
                                alt="Imagen 1"
                                className="carousel-image"
                            />
                        </div>
                        <div>
                            <img
                                src="https://menhir1.com/wp-content/uploads/2024/04/sofia-sanchez-UcoDZZ7DCXY-unsplash-2.jpeg"
                                alt="Imagen 2"
                                className="carousel-image"
                            />
                        </div>
                    </Carousel>
                </div>

                
            </div>

            {/* Sección de proyectos */}
            <Box sx={{ mt: 4, textAlign: 'center', marginTop: '5vw' }}>
                <Typography variant="h3" component="h2" sx={{ mb: 3 }}>
                    Mis últimos proyectos
                </Typography>

                <Grid container spacing={3} justifyContent="center" style={{ marginTop: '4vw'}}>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/menhir-proyectos-17-1-510x340.png"
                            title="Triumph"
                            subtitle="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour."
                            date="09/12/2024"
                        />
                    </Grid>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/menhir-proyectos-18-1-510x340.png"
                            title="Kawasaki"
                            subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                            date="10/12/2024"
                        />
                    </Grid>
                    <Grid item>
                        <Project
                            image="https://menhir1.com/wp-content/uploads/2024/04/fotos-website-menhir-ok-11-510x340.jpg"
                            title="Motoplex Devoto"
                            subtitle="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old."
                            date="11/12/2024"
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Sección gráficos */}
            <Box sx={{ mt: 4, textAlign: 'center', marginTop: '5vw' }}>
                <Typography variant="h3" component="h2" sx={{ mb: 3 }}>
                    Reporte de estado de proyectos 
                </Typography>
                <Grid container spacing={3} justifyContent="center">
                    <Pie />
                </Grid>
            </Box>
        </Box>
    );
};

export default Home;
